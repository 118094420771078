<template>
    <div class="container">
        <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      size="24px"
      >加载中...</van-loading
    >
    </div>
  </template>
  
  <script>
  //import Vue from 'vue';
  import store from "@/store";
  
  
  
  export default {
    name: "vip",
    data() {
      return {
        url: ""
      };
    },
    created() {
      this.initData();
      
    },
  
    methods: {
      async initData() {
        this.url=decodeURI( this.$route.query.url);
        window.location.href = this.url + ( this.url.indexOf("?") == -1 ? "?" : "&" ) + "token=" + store.state.user.token;
      }
  
    },

  };
  </script>
  
  <style lang="less" scoped>
  .btn-box {
    padding: 20px;
  }
  .loading {
  text-align: center;
}
  </style>
  